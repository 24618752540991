<template>
  <div class="day-legend" v-bind="$attrs" v-on="$listeners">
    <img src="@/assets/IC_Vacaciones_Aprobada.png" /><span v-if="withTitle">{{
      $t("pages.permission.vacations.approved")
    }}</span>
  </div>
</template>

<script>
export default {
  props: {
    withTitle: {
      type: Boolean,
      default: true,
    },
  },
};
</script>

<style></style>
